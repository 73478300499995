import { Grid } from "@mui/material";
import { IAchievementContent } from "../../types/core";
import Icon from "../core/Icon";

import "../../styles/achievements.scss";

interface IProps extends IAchievementContent {}

export default function AchievementCard(props: IProps) {
	const { title, icon, description } = props;
	const { url } = icon;

	return (
		<div className="AchievementCard">
			<a href={url} target="_blank" rel="noreferrer">
				<Grid container alignItems="center" spacing={1}>
					<Grid container item xs direction="column">
						<Grid item>
							<h5 className="text-highlight">{title}</h5>
						</Grid>
						<Grid item xs>
							<p>{description}</p>
						</Grid>
					</Grid>
					<Grid item>
						<Icon {...icon} size={64}></Icon>
					</Grid>
				</Grid>
			</a>
		</div>
	);
}
