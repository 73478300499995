import { Grid } from "@mui/material";
import { Skill } from "../../types/core";

interface IProps {
	tag: string;
	skills: Skill[];
}

export default function SkillCard(props: IProps) {
	const { tag, skills } = props;

	return (
		<div className="SkillCard">
			<Grid container direction="column">
				<Grid item className="skillTitle">
					<h6 className="text-highlight">{tag}</h6>
				</Grid>
				<Grid item container direction="column">
					{skills.map((skill: Skill) => (
						<Grid item>{skill.title}</Grid>
					))}
				</Grid>
			</Grid>
		</div>
	);
}
