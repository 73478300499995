import { SkillGroup } from "../types/core";
import getSkillGroups from "./api/skills/get-skills";
import { Container, Grid } from "@mui/material";

import "../styles/skills.scss";
import SkillCard from "../components/skills/SkillCard";

export function Skills() {
	const skillGroups: SkillGroup[] = getSkillGroups();

	const skillCards = skillGroups.map((skillGroup: SkillGroup) => {
		return (
			<Grid item key={skillGroup.tag} className="skillContainer">
				<SkillCard {...skillGroup} />
			</Grid>
		);
	});

	return (
		<Container maxWidth={false} disableGutters className="Skills">
			<h2 className="SectionTitle">Skills</h2>
			<Grid container justifyContent="space-evenly">
				{skillCards}
			</Grid>
		</Container>
	);
}
