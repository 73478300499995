import React, { useMemo, useState } from "react";
import JobCard from "../components/experience/JobCard";
import { IExperienceContent } from "../types/core";
import getExperienceContent from "./api/experience/get-experience-content";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Container, ToggleButton } from "@mui/material";

import "../styles/experience.scss";
import { getToggleButtonClassName } from "../api/CssHelpers";

export function Experience() {
	const jobList = getExperienceContent();

	const [selectedJobIndex, setSelectedJobIndex] = useState(0);

	const jobToggleButtons = useMemo(
		() =>
			jobList.map((job: IExperienceContent, index: number) => {
				const className = ["ToggleButton"];
				className.push(
					getToggleButtonClassName(index, jobList.length - 1)
				);

				return (
					<ToggleButton
						key={index}
						value={index}
						selected={index === selectedJobIndex}
						className={className.join(" ")}
						size="small"
					>
						{job.company}
					</ToggleButton>
				);
			}),
		[jobList, selectedJobIndex]
	);

	const onChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
		setSelectedJobIndex(value);
	};

	return (
		<Container maxWidth={false} disableGutters className="Experience">
			<h2 className="SectionTitle">Where I've Worked</h2>
			<ToggleButtonGroup
				exclusive
				fullWidth
				onChange={onChange}
				className="ToggleButtons"
			>
				{jobToggleButtons}
			</ToggleButtonGroup>
			<JobCard {...jobList[selectedJobIndex]} />
		</Container>
	);
}
