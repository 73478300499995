import { IExperienceContent } from '../../../types/core';

export default function getExperienceContent(): IExperienceContent[] {
	return [
		{
			company: "Epic",
			title: "Full Stack Software Developer, MyChart",
			subTitle: "Epic Systems",
			link: {
				url: "https://www.epic.com/about",
				displayString: "Epic Systems"
			},
			icon: {
				path: "logos/epic-logo.png",
				alt: "Epic",
				url: "https://www.epic.com/about"
			},
			dateRange: {
				startDate: new Date("2021-05-10"),
				endDate: new Date()
			},
			location: "Rochester, Minnesota",
			descriptionList: [
				"Developed an accessible alternative for a React graphing framework to aid sight impaired users",
				"Enhanced infrastructure to allow an existing workflow to be accessed as a guest",
				"Enabled the aforementioned guest workflow to be utilized in multi-deployment cases",
				"Implemented a system for configuring and presenting dynamic patient care options",
				"Established the means for healthcare organizations to subscribe to Epic released configuration",
				"Led customer facing project meetings with participants from high profile healthcare organizations",
				"Managed high level project investigation, design, and development as well as team scrum processes"
			]
		},
		{
			company: "Magenic",
			title: "Associate Consultant II",
			subTitle: "Magenic (Cognizant Softvision)",
			link: {
				url: "https://www.cognizantsoftvision.com/about-us/",
				displayString: "Magenic (Cognizant Softvision)"
			},
			icon: {
				path: "logos/magenic-logo.png",
				alt: "Magenic",
				url: "https://www.cognizantsoftvision.com/about-us/"
			},
			dateRange: {
				startDate: new Date("2019-10-14"),
				endDate: new Date("2021-03-19")
			},
			location: "St. Louis Park, Minnesota",
			descriptionList: [
				"Developed a Java/Spring microservice for loading, merging, and querying custom calendar data",
				"Refined and simplified the flow of message retrieval and event chains in a scheduling application",
				"Developed and tested new features for a robust financial calculation engine",
				"Contributed to a Java/Angular webapp for medical lab test ordering",
				"Conducted technical interviews for new associate consultant candidates"
			]
		},
		{
			company: "X-ES",
			title: "Asociate Developer",
			subTitle: "Extreme Engineering Solutions",
			link: {
				url: "https://www.xes-inc.com/about/",
				displayString: "Extreme Engineering Solutions"
			},
			icon: {
				path: "logos/xes-logo.png",
				alt: "Extreme Engineering Solutions",
				url: "https://www.xes-inc.com/about/"
			},
			dateRange: {
				startDate: new Date("2017-05-22"),
				endDate: new Date("2017-12-22")
			},
			location: "Middleton, Wisconsin",
			descriptionList: [
				"Investigated and established a web app testing pipeline and testing library with Jenkins and Selenium",
				"Created a suite of Perl scripts to validate product manufacturing info across database instances"
			]
		},
	]
}