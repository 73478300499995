import { Container, Grid } from "@mui/material";
import { dateToMonthYear, isDateToday } from "../../api/DateHelpers";
import { IExperienceContent } from "../../types/core";
import Link from "../core/Link";

interface IProps extends IExperienceContent {}

export default function JobCard(props: IProps) {
	const { title, subTitle, descriptionList, link, dateRange } = props;
	const { startDate, endDate } = dateRange;

	const linkElement = link ? (
		<Link url={link.url} displayString={link.displayString}></Link>
	) : (
		subTitle
	);

	return (
		<Container className="JobCard">
			<Grid container justifyContent="space-between">
				<Grid container item xs>
					<Grid item xs={12} md="auto">
						<p className="title">{title}</p>
					</Grid>
					<Grid item xs={12} md="auto">
						<p>{linkElement}</p>
					</Grid>
				</Grid>
				<Grid item xs={12} md="auto">
					<p>
						{dateToMonthYear(startDate)} -{" "}
						{isDateToday(endDate)
							? "Present"
							: dateToMonthYear(endDate)}
					</p>
				</Grid>
			</Grid>
			<ul>
				{descriptionList.map((item) => (
					<li>{item}</li>
				))}
			</ul>
		</Container>
	);
}
