import { ILink } from "../../types/core";

interface IProps extends ILink {}

export default function Link(props: IProps) {
	const { url, displayString } = props;
	return (
		<a href={url} target="_blank" rel="noreferrer">
			{displayString}
		</a>
	);
}
