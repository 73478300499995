import { IAchievementContent } from '../../../types/core';

export default function getAchievementContent(): IAchievementContent[] {
	return [
		{
			title: "Personal Website",
			description: "The site you're currently on! Built from scratch with React, Typescript, and Material UI. Hosted on AWS.",
			icon: {
				path: "logos/NB.svg",
				alt: "NB",
				// url: "#"
			}
		},
		{
			title: "Triangulum",
			description: "Began development of Triangulum, a minimalist logic puzzle game, in Unity during the summer of 2018. Incorporated N5 Games in June, 2019. Finished development and released Triangulum on Windows, Mac, and Linux on July 30th, 2019 via Steam.",
			icon: {
				path: "logos/triangulum-logo.png",
				alt: "Triangulum",
				url: "https://store.steampowered.com/app/1105140/Triangulum/"
			}
		},
		{
			title: "AWS Certified Developer - Associate",
			description: "Learned how to develop applications utilizing a plethora of cloud services from AWS. Breadth of knowledge includes API creation, deployment methods, version control integrations, security, database services, and more.",
			icon: {
				path: "logos/aws-icon.jpg",
				alt: "Amazon Web Services Certification",
				url: "https://www.credly.com/badges/83f9818d-cb60-491a-a7b3-99ee51bd2f4b?source=linked_in_profile"
			}
		},
		{
			title: "SAFe 5 Certified Practitioner",
			description: "Learned processes of SAFe (Scaled Agile Framework) which covers planning increments (sprints) and iterations, breaking project requirements into stories, responding to changing specifications, and demoing completed work.",
			icon: {
				path: "logos/safe-icon.jpg",
				alt: "Scaled Agile Framework Certification",
				url: "https://www.credly.com/badges/e6e48a01-e20e-4964-916f-b3690978a2a6?source=linked_in_profile"
			}
		},
		{
			title: "UW-Madison",
			icon: {
				path: "logos/uw-madison-icon.jpg",
				alt: "UW-Madison",
				url: "https://www.wisc.edu/about/"
			},
			description: "GPA - 3.7 / 4.0\nBachelor's degree in Computer Science with certificates in both Business and Math. Tutored at the computer sciences learning center."
		},
	]
}