import { Grid } from "@mui/material";
import Link from "../components/core/Link";
import { ILink } from "../types/core";
import getLinks from "./api/links/get-links";

export function Links() {
	const linkContent: ILink[] = getLinks();

	const links = linkContent.map((link: ILink) => {
		return (
			<Grid item key={link.displayString}>
				<Link {...link} />
			</Grid>
		);
	});

	return (
		<Grid container spacing={3}>
			{links}
		</Grid>
	);
}
