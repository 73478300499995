import { ILink } from "../../../types/core";

export default function getLinks(): ILink[] {
	return [
		{
			url: "https://www.linkedin.com/in/nolan-byrne/",
			displayString: "LinkedIn",
		},
		{
			url: "https://github.com/Noles55",
			displayString: "GitHub",
		},
		{
			url: "mailto:nolan.byrne@comcast.net",
			displayString: "Email"
		}
	];
}