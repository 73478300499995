import { Container, Grid } from "@mui/material";
import { Links } from ".";
import Link from "../components/core/Link";
import "../styles/about.scss";

export function About() {
    return (
        <Container maxWidth={false} disableGutters className="About">
            <Grid container spacing={4} justifyContent="center">
                <Grid item>
                    <div className="profilePictureContainer">
                        <img
                            className="profilePicture"
                            src="nolan_byrne_profile.jpg"
                            alt="Nolan Byrne"
                            width={256}
                            height={256}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    container
                    md={7}
                    direction="column"
                    justifyContent="center"
                >
                    <Grid item>
                        <h1>
                            Hi, I'm{" "}
                            <span className="text-highlight">Nolan</span>
                        </h1>
                    </Grid>
                    <Grid item>
                        <h2>I develop software</h2>
                    </Grid>
                    <Grid item>
                        <p>
                            I'm a full stack software engineer and independent
                            game developer with a passion for creating
                            incredible interactive experiences. I'm currently at
                            <Link
                                url="https://www.epic.com/about"
                                displayString=" Epic "
                            />
                            expanding the way patients receive care via MyChart.
                        </p>
                    </Grid>
                    <Grid item>
                        <Links />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
