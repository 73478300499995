import { Container, Grid } from "@mui/material";
import AchievementCard from "../components/education/AchievementCard";
import { IAchievementContent } from "../types/core";
import getAchievementContent from "./api/achievements/get-achievement-content";

export function Achievements() {
	const achievementContent: IAchievementContent[] = getAchievementContent();

	const achievementCards = achievementContent.map(
		(achievement: IAchievementContent) => (
			<Grid item key={achievement.title}>
				<section className="achievementContainer">
					<AchievementCard {...achievement}></AchievementCard>
				</section>
			</Grid>
		)
	);

	return (
		<Container maxWidth={false} disableGutters className="Achievements">
			<h2 className="SectionTitle">Other Things I've Done</h2>
			{/* For medium and up display two columns so height of cards in each row can differ */}
			<Grid
				container
				spacing={2}
				sx={{ display: { xs: "none", md: "flex" } }}
			>
				<Grid
					item
					container
					xs={12}
					md={6}
					spacing={2}
					direction="column"
				>
					{achievementCards.filter((card, index) => index % 2 === 0)}
				</Grid>
				<Grid
					item
					container
					xs={12}
					md={6}
					spacing={2}
					direction="column"
				>
					{achievementCards.filter((card, index) => index % 2 === 1)}
				</Grid>
			</Grid>
			{/* For small, render as normal since it will only be one column anyway*/}
			<Grid
				container
				spacing={2}
				direction="column"
				sx={{ display: { xs: "flex", md: "none" } }}
			>
				{achievementCards}
			</Grid>
		</Container>
	);
}
