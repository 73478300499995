import { Skills } from ".";
import { About } from "./About";
import { Achievements } from "./Achievements";
import { Experience } from "./Experience";

import "../styles/home.scss";

export function Home() {
	return (
		<section className="Home">
			<About />
			<Skills />
			<Experience />
			<Achievements />
		</section>
	);
}
