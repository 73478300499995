import { SkillGroup } from "../../../types/core";

export default function getSkillGroups(): SkillGroup[] {
	return [
		{
			tag: "Front End",
			skills: [
				{
					title: "TypeScript",
					proficiency: 8,
				},
				{
					title: "React",
					proficiency: 7,
				},
				{
					title: "Angular",
					proficiency: 3,
				},
				{
					title: "Redux",
					proficiency: 3,
				},

			]
		},
		{
			tag: "Back End",
			skills: [
				{
					title: "Java",
					proficiency: 8,
				},
				{
					title: "Spring",
					proficiency: 6,
				},
				{
					title: "C#",
					proficiency: 7,
				},
				{
					title: ".NET",
					proficiency: 7,
				},
			]
		},
		{
			tag: "Database",
			skills: [
				{
					title: "SQL",
					proficiency: 4,
				},
				{
					title: "Caché/M",
					proficiency: 7,
				},
			]

		},
		{
			tag: "Version Control",
			skills: [
				{
					title: "Git",
					proficiency: 8,
				},
				{
					title: "SVN",
					proficiency: 5,
				},
			]
		},
		{
			tag: "Cloud",
			skills: [
				{
					title: "AWS",
					proficiency: 6,
				},
				{
					title: "PCF",
					proficiency: 4
				}
			]
		},
		{
			tag: "Other",
			skills: [
				{
					title: "Unity",
					proficiency: 7
				},
				{
					title: "Agile/Scrum",
					proficiency: 8
				}
			]
		}
	]
}



