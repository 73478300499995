export function dateToMonthYear(date: Date) {
	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short' };

	return date.toLocaleString("en-US", options);
}

export function isDateToday(date: Date) {
	const today = new Date();
	return date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear();
}