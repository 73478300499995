interface IProps {
	path: string;
	alt: string;
	size: number;
	url?: string;
}
export default function Icon(props: IProps) {
	const { path, alt, size, url } = props;
	return url ? (
		<a href={url} target="_blank" rel="noreferrer">
			<img
				className="Icon"
				src={path}
				alt={alt}
				width={size}
				height={size}
			/>
		</a>
	) : (
		<img className="Icon" src={path} alt={alt} width={size} height={size} />
	);
}
